:root { // ps-dark | default
    --primary-color: #00ff6a;
    --secondary-color: #6affbf;
    --border-color: #00753c;
    --section-color: #262b2b;
    --background-color: #131616;
    --hover-color: #505755;
    --text-color: #FFFFFF;
}

:root.ps-light {
    --primary-color: #1DB65D;
    --secondary-color: #22CA6C;
    --border-color: #96D9B9;
    --section-color: #D3E6E0;
    --background-color: #F7FFFB;
    --hover-color: #C6E1DA;
    --text-color: #141916;
}

:root.trans {
    --primary-color: #F5ABB9;
    --secondary-color: #5BCFFB;
    --border-color: #ce7b90;
    --section-color: #d9f1ff;
    --background-color: #ffffff;
    --hover-color: #c4e5ff;
    --text-color: #131616;
}

:root.enby {
    --primary-color: #FCF434;
    --secondary-color: #9C59D1;
    --border-color: #6e4f86;
    --section-color: #2C2C2C;
    --background-color: #1a1a1a;
    --hover-color: #3b3b3b;
    --text-color: #FFFFFF;
}

:root.dph {
    --primary-color: #FF8904;
    --secondary-color: #F2491A;
    --border-color: #44403B;
    --section-color: #292524;
    --background-color: #1C1917;
    --hover-color: #44403B;
    --text-color: #E7E5E4;
}

main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

h1 {
    margin: 0;
}

h2 {
    margin: 0;
}

h3 {
    margin: .5rem 0 .25rem 0;
}

p {
    margin-bottom: .5rem;
}

button {
    font-family: "Lexend", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

div.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
}

.section {
    background-color: var(--section-color);
    padding: 1rem;
    border-radius: .25rem .25rem 0 0;
    display: flex;
    flex-direction: column;
    border-bottom: .125rem solid var(--border-color);
}

a {
    color: var(--secondary-color);
    transition: color 0.2s ease-in-out;
    
    &:hover {
        color: var(--primary-color);
    }
}

b {
    font-weight: 900;
}

code {
    background-color: var(--background-color);
    border-radius: .25rem;
    padding: .125rem .25rem;
}

.spinner_5nOS{
    transform-origin: center;
    animation: spinner_sEAn .75s infinite linear;
}

@keyframes spinner_sEAn {
    100% { transform: rotate(360deg) }
}
