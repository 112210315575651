@import '../../styles.scss';

.cardboard_header {
    display: flex;
    gap: .5rem;
    align-items: baseline;

    span {
        color: var(--section-color);
    }
}

.cardboard_row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    label {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
