@import './../styles.scss';

div.upload_multiple {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input.upload {
        width: 16rem;
        height: 6.5rem;
        border: .125rem solid var(--border-color);
        border-radius: .25rem;
        display: flex;
        color: var(--text-color);
        font-weight: 700;
        transition: border-color 0.2s ease-in-out;
        padding: .5rem;
        position: relative;
        background-color: var(--background-color);

        &::file-selector-button {
            position: absolute;
            top: 3.75rem;
            left: 8.5rem;
            transform: translate(-50%, -50%);
            border: none;
            background-color: var(--section-color);
            color: var(--text-color);
            border-radius: .25rem;
            padding: 1rem 2rem;
            font-weight: 700;
        }

        &:hover {
            border-color: var(--primary-color);
        }
    }

    div.file_name {
        display: flex;
        justify-content: space-between;
        background-color: var(--background-color);
        border-radius: .25rem;
        padding: .5rem;
    }
}
