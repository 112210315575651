@import './../styles.scss';

a.link_card {
    width: 16rem;
    height: 6.125rem;
    border-radius: .25rem;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem;
    color: var(--text-color);
    text-decoration: none;
    position: relative;

    h3 {
        margin: 0;
    }

    &:hover {
        background-color: var(--hover-color);
        color: var(--text-color);
    }

    .flag {
        position: absolute;
        right: .5rem;
        background-color: var(--primary-color);
        color: var(--background-color);
        padding: .25rem;
        border-radius: .25rem;
        font-size: .75rem;
        line-height: .75rem;
        font-weight: 700;
        justify-self: flex-end;
    }

    .link_card_title {
        width: fit-content;
        display: flex;
        gap: .25rem;
        border-bottom: .125rem solid var(--text-color);

        svg {
            max-width: 1.5rem;
            max-height: 1.5rem;
        }
    }
}
