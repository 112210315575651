@import '../styles.scss';

main.home {
    div.project_stats {
        display: flex;
        gap: .5rem;
        align-items: center;
        margin-top: .5rem;

        svg {
            max-height: 1.5rem;
            max-width: 1.5rem;
        }

        .stat {
            background-color: var(--background-color);
            padding: .125rem .25rem;
            border-radius: .25rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 1.25rem;

            svg {
                max-height: 1rem;
                max-width: 1rem;
            }
        }
    }

    div.title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 1rem 0;

        h3 {
            margin: 0;
        }

        h1 {
            margin: 0;
            font-size: 3.5rem;
            background: linear-gradient(120deg, var(--primary-color) 30%, var(--secondary-color) 50%, var(--primary-color) 70%);
            background-size: 200% 200%;
            background-clip: text;
            color: transparent;
            animation: Gradient 2s ease infinite;
        }

        @keyframes Gradient {
            0% { background-position: 150% 50%; }
            100% { background-position: -50% 50%; }
        }
    }

    .skill_container {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        margin: .5rem 0;
        width: fit-content;

        a.skill {
            display: flex;
            gap: .25rem;
            align-items: center;
            background-color: var(--background-color);
            padding: .25rem .5rem;
            border-radius: .25rem;
            text-decoration: none;

            &:hover {
                background-color: var(--hover-color);
            }

            svg {
                max-height: 1.75rem;
                max-width: 1.75rem;
            }
        }
    }
}
