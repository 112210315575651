@import '../../styles.scss';

main.eldenringdle {
    display: flex;

    div.game {
        display: flex;
        gap: 1rem;

        div.search {
            width: 62rem;
            max-width: 100%;
            position: relative;
            margin: auto;

            input {
                width: calc(100% - 1.5rem);
                background-color: var(--hover-color);
                padding: .5rem;
                display: flex;
                flex-direction: column;
                border: .125rem solid var(--border-color);

                font-size: 1rem;
                color: var(--text-color);
                font-family: "Lexend", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif;
            }

            div.shownitems {
                position: absolute;
                z-index: 100;
                width: calc(100% - .25rem);
                max-height: 29.8rem;
                overflow-y: auto;

                button.item {
                    width: 100%;
                    background-color: var(--background-color);
                    padding: .5rem 2rem .5rem .5rem;
                    display: flex;
                    flex-direction: row;
                    border: .125rem solid var(--hover-color);
                    gap: .5rem;
                    font-size: 1rem;
                    color: var(--text-color);
                    cursor: pointer;
    
                    &:hover {
                        background-color: var(--hover-color);
                    }
    
    
                    img {
                        height: 3rem;
                    }
                }
            }
        }

        div.guessed {
            text-align: center;
            overflow-x: auto;
            word-wrap: break-word;

            div.header {
                display: flex;

                &>*:first-child {
                    margin-left: auto;
                }
                
                &>*:last-child {
                    margin-right: auto;
                }

                &>* {
                    border: .125rem solid transparent; 
                    border-bottom: .125rem solid var(--border-color);
                    width: 5rem;
                    min-width: 5rem;
                    margin: .5rem .5rem 0 .5rem;
                    padding-bottom: .5rem;
                }
            }

            div.item {
                display: flex;

                div.cell:first-child {
                    margin-left: auto;
                }
                
                div.cell:last-child {
                    margin-right: auto;
                }

                div.cell {
                    width: 5rem;
                    min-width: 5rem;
                    min-height: 5rem;
                    margin: .5rem;
                    background-color: var(--background-color);
                    border: .125rem solid transparent;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        height: 4rem;
                        width: 4rem;
                    }

                    span {
                        width: 100%;
                        padding: .25rem;
                    }

                    svg {
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        position: absolute;
                        color: color-mix(in srgb, var(--text-color) 30%, transparent 70%);
                    }
                }
            }
        }

        div.footer {
            display: flex;
            gap: .25rem;
            margin: auto;
            margin-top: 1rem;
            align-items: center;
            color: var(--text-color);

            img {
                height: 3rem;
            }
        }

        div.correct {
            display: flex;
            gap: .5rem;
            margin: auto;
            flex-direction: column;
            border: .125rem solid var(--border-color);
            border-radius: .25rem;
            padding: .5rem;
            text-align: center;
            width: 62rem;
            max-width: calc(100% - 2rem);
            background-color: var(--background-color);

            div.item {
                display: flex;
                gap: .25rem;
                margin: auto;

                img {
                    height: 5.125rem;
                }
            }
        }
    }

    span.disclaimer {
        margin: auto;
        color: var(--hover-color);

        a {
            color: var(--hover-color);
        }
    }
}
